<template>
  <div>
    <topnav></topnav>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">{{pageContent.title}}</h1>
        <div class="small-title-line center"></div>
      </div>
<div class="don-wrap">
        <div class="don-top ">
          <div class="don-top-txt-wrap1" v-html="pageContent.donationMessage">
          </div>
        </div>
        <iframe class="newIframe" style="border:0;" width="100%" height="100%" src=" https://secure.cardknox.com/lamdeini "></iframe>
</div>
      <div class="don-bot">
        <div class="don-gray-box" v-html="pageContent.amountsText">
        </div>
        <div class="don-list-wrap">
          
<div v-for="amount in pageContent.recommendedAmounts"  :key="amount._id">
            <button v-if="amount.showOnPage" class="don-item"  @click="selectAmount(amount.amount)">
              <p class="don-item-txt">{{amount.title}}</p>
              <p class="don-price">${{amount.amount.toLocaleString()}}</p>
            </button>
</div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  data(){
    return {amount:"", pageContent:{}}
  },
  computed:{
    defaultInterval(){
      return this.amount?'o':'m'
    }
  },
  mounted(){
    this.getContents()
  },
  methods:{
    async getContents(){
      const {data:[pageContent]} = await this.$db.collection('donatePage').everything().get()
      this.pageContent = pageContent
    },
    selectAmount(amount){
      this.amount = amount;
      const mainSec = document.querySelector('.main-sec')
      mainSec.scrollIntoView({behavior:"smooth"})
    }
  },
  name: "Donate",
};
</script>


<style scoped>
.newIframe{
  height: 100vh;
  width: 100%;
  padding: 1rem;
}
.don-wrap{
  max-width: 1200px;
  gap: 1rem;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}


@media screen and (max-width: 991px) {
  .don-wrap{
    flex-direction: column;
  }
}
</style>